@import "./variables.scss";

.color {
  &_green {
    color: $primaryGreen !important;
  }

  &_darkLiver {
    color: $darkLiver !important;
  }

  &_black {
    color: $black !important;
  }

  &_red {
    color: $red !important;
  }

  &_denim {
    color: $denim !important;
  }

  &_GraniteGray2 {
    color: $GraniteGray2 !important;
  }

  &_primaryBlue {
    color: $primaryBlue !important;
  }
}

.font {
  &_font-12 {
    font-size: $sp12;
  }

  &_font-14 {
    font-size: $sp14;
  }

  &_font-16 {
    font-size: $sp16;
  }

  &_font-18 {
    font-size: $sp18;
  }

  &_font-20 {
    font-size: $sp20;
  }

  &_font-24 {
    font-size: $sp24;
  }

  &_font-36 {
    font-size: $sp36;
  }
}

.fontWeight {
  &_fw-200 {
    font-weight: $fw200;
  }

  &_fw-300 {
    font-weight: $fw300;
  }

  &_fw-400 {
    font-weight: $fw400;
  }

  &_fw-500 {
    font-weight: $fw500;
  }

  &_fw-600 {
    font-weight: $fw600;
  }

  &_fw-700 {
    font-weight: $fw700;
  }
}
