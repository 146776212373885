.modal-chat {
    margin-left: 37% !important;
}
.font-weight-bold {
    font-weight: bold;
    margin-bottom: 5px;
}
.alert-icon {
    position: relative;
    left: 13px;
}
.alert-img {
    width: 42px;
}