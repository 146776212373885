@import "../../assets/styles/variables.scss";

.URMPage {
  height: calc(100vh - 80px);
  overflow: auto;
  background: $white;
}

.URMPage {
  .header-container {
    background-color: $darkBlue;
    .header-breadcrumb {
      font-weight: normal;
      font-size: $sp14;
      color: $lightGrey2;
      padding-top: 24px;
    }
    .header-title {
      font-weight: $fw300;
      font-size: $sp36;
      color: $white;
      padding: 16px 0px 24px 0px;
    }
  }
  .preference-container {
    margin: 40px 0px 50px 0px;
  }

  .search-label {
    width: 100%;
  }
}

.notFundContainer {
  padding: 40px;
  text-align: center;
  margin-top: 160px;
}
.errtitle {
  font-weight: $fw500;
  font-size: $sp36;
  color: $purple;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
  overflow: initial !important;
}
