@import "../../assets/styles/variables.scss";

.navigationBar {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  height: 80px;

  &__linkGroup {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &__link {
      font-size: $sp18;
      margin: 0 30px;
      color: $black;
      font-weight: $fw400;
      font-style: normal;
      line-height: 25px;

      &--active {
        font-weight: $fw700;
        text-decoration: none !important;
        border-bottom: 4px solid $primaryGreen;
        border-radius: 0;
      }

      &:hover,
      &:focus {
        text-decoration: none !important;
        box-shadow: none;
        outline: none;
        color: $black;
      }
    }
  }

  .logout-btn {
    border-width: 0px;
    color: $red !important;
    font-size: $sp16;
    &:hover {
      border: none !important;
    }
  }
}

.navdropdownHeader {
  .dropdown-menu {
    margin-left: -310% !important;
    width: 350px;
    top: 67px;

    .alignNavBartext {
      justify-content: center;
    }

    .manageText {
      font-size: $sp12;
      color: $graniteGray;
      margin-left: 9px;
      margin-bottom: 9px;
    }

    .iconHeight {
      height: 25px;
    }

    .videoMeets {
      margin-left: 1.5rem;
      padding: 3px;
    }

    .appointmentPrefText {
      font-size: $sp16;
      color: $black;
      font-weight: $fw600;
      text-align-last: start;
      cursor: pointer;
    }

    .calendarimageLogout {
      transform: rotate(90deg);
    }

    .hrWidth {
      width: 100%;
    }
  }
}

.red-dot {
  margin-top: -27px;
}

.chat-count {
  position: relative;
  border-radius: 28%;
  padding: 0px 4px;
  background-color: $red;
  font-size: $sp12;
  color: $white;
  font-weight: $fw700;
  vertical-align: top;
  z-index: 1;
}

.chat-icon {
  width: 28px;
  cursor: pointer;
  padding-top: 7px;
  position: relative;
  left: 4px;
  z-index: 1;
}
