@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "nprogress";
@import "bootstrap-daterangepicker/daterangepicker.css";
@import "typography";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: $lightGrey2;
}

html body {
  background-color: $lightGrey2;
}

//  ___________________________________common styles that are usable across entire application_________________________________________
.card {
  box-shadow: 0px 6px 10px rgba(217, 221, 232, 0.14),
    0px 1px 18px rgba(217, 221, 232, 0.12), 0px 3px 5px rgba(217, 221, 232, 0.2);
  border-radius: 6px;
  overflow: hidden;

  img,
  .img-thumbnail {
    background: $lightGrey2;
  }
}

.overflow-visible {
  overflow: visible;
}

.btn {
  font-weight: bold;
}

a {
  text-decoration: none;
  background-color: transparent;
}

label {
  margin-bottom: 0.5rem;
}

.p-10 {
  padding: 10px;
}

.cursor-pointer,
.cursor_pointer {
  cursor: pointer;
}

.cursor-unset {
  cursor: unset !important;
}

.float-icon-right {
  float: right;
}

.display-inline-flex {
  display: inline-flex;
}

.disable-html-tag {
  pointer-events: none;
  opacity: 0.4;
}

// common tooltip
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltipArrow {
  color: $white !important;
  background-color: $darkLiver2 !important;
  padding: 10px !important;
  font-size: $sp12 !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  color: $white !important;
  background-color: $darkLiver2 !important;
  padding: 10px !important;
  font-size: $sp12 !important;
}

// RPM Dashboard HTML popup
.MuiTooltip-popperInteractive {
  .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
    background-color: $white !important;
    font-size: $sp16 !important;
    font-weight: $fw400 !important;
    color: $black !important;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
  }
}

.p-a {
  position: absolute !important;
}

.p-r {
  position: relative !important;
}

.active_tab_green_bottom {
  color: $primaryGreen !important;
  border-bottom: 4px solid $primaryGreen !important;
}
//  ___________________________________common styles that are usable across entire application_________________________________________

//  ___________________________________ styles that are used across multiple components________________________________________________
.care-area-form-container .rbt-menu {
  width: 100% !important;
}

.view-survey {
  padding: initial;
}

legend {
  float: none;
}

.ip-icon {
  .input-group-append {
    top: 8px;
    position: absolute;
    right: 10px;
  }
}

.dot-blue {
  height: 10px;
  width: 10px;
  background-color: $blueberry;
  border-radius: 50%;
  display: inline-block;
}

.dot-grey {
  height: 10px;
  width: 10px;
  background-color: $mediumGrey;
  border-radius: 50%;
  display: inline-block;
}

.dot-yellow {
  height: 10px;
  width: 10px;
  background-color: $yellow2;
  border-radius: 50%;
  display: inline-block;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: 6px;
}

.ip-icon.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-radius: 6px;
}

.gh-form-label {
  font-size: $sp14;
  line-height: 20px;
}

.gh-grey-card {
  background: $lightGrey2;
  border-radius: 6px;
  padding: 24px 15px 24px 24px;

  .heading {
    font-weight: bold;
    font-size: $sp18;
    line-height: 25px;
    color: $black;
  }

  .content-text {
    font-size: $sp16;
    line-height: 22px;
    color: $black;
    margin: 16px 0px;
  }
}

.gh-p10 {
  padding: 10px;
}

.gh-dual-line-list {
  display: flex;
  padding: 10px 0px;
  text-overflow: ellipsis;
  overflow: hidden;

  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .list-item {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    white-space: nowrap;

    .primary-text {
      font-weight: bold;
      font-size: $sp16;
      line-height: 22px;
    }

    .secondary-text {
      font-size: $sp14;
      line-height: 19px;
      color: $darkCharcoal;
    }
  }
}

.gh-btn-primary {
  @include button-variant(
    $primaryBlue,
    $primaryBlue,
    $denim,
    $denim,
    $denim,
    $white,
    $denim
  );

  color: $white;
  &:disabled,
  &.disabled {
    color: $disableButtonColor;
    background-color: $manatee;
    border-color: $manatee;
  }
}

.gh-btn-outline-primary {
  @include button-outline-variant($primaryBlue, $denim, $white, $denim);

  &:disabled,
  &.disabled {
    color: $manatee;
    border-color: $manatee;
  }

  &:active {
    color: $primaryBlue;
  }
}

.gh-btn-link-primary {
  @include button-outline-variant($primaryBlue, $denim, #00000000, $denim);

  &:disabled,
  &.disabled {
    color: $manatee;
    border-color: $manatee;
  }

  border: 0px;

  &:focus {
    @if $enable-shadows {
      @include box-shadow($btn-active-box-shadow, none);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: none;
    }
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: $denim;
  }
}

.gh-btn-success {
  @include button-variant($green, $green, $green, $green, $green, $green);
  color: $white;

  &:disabled,
  &.disabled {
    color: $disableButtonColor;
    background-color: $manatee;
    border-color: $manatee;
  }
}

.gh-btn-danger {
  @include button-variant($red, $red, $red, $red, $red, $red);
  color: $white;

  &:disabled,
  &.disabled {
    color: $disableButtonColor;
    background-color: $manatee;
    border-color: $manatee;
  }
}

.gh-select {
  border-radius: 6px;
  color: $black;
  font-size: $sp16;
  font-weight: $fw400;
  padding: 6px 2px 6px 12px;

  &:focus-visible {
    outline: none;
  }
}

.gh-select-primary {
  border-radius: 6px;
  color: $primaryBlue;
  font-size: $sp16;
  font-weight: bold;
  padding: 6px 2px 6px 12px;
  border: 1px solid $primaryBlue;

  &:focus-visible {
    outline: none;
  }
}

.gh-table {
  thead {
    th {
      background-color: $lightGrey2;
      color: $blackCoral;
      font-weight: $fw600;
      border-style: none;
      white-space: nowrap;
      padding: 16px 24px;
    }
  }

  &.table > :not(:first-child) {
    border: 0px solid $gainsboro;
  }

  tbody {
    tr {
      td {
        padding: 16px 24px;
        word-break: unset;
        vertical-align: middle;
      }
    }
  }
}

.card .img-thumbnail {
  background: none;
}

.gh-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gainsboro;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, $lavenderBlue 14%, $pinkLace 64%);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.gh-small-info {
  color: $graniteGray;
  font-size: $sp14;
  font-weight: $fw400;
}

.gh-table-basic {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  border: 1px solid $gainsboro;
  border-radius: 6px;

  .gh-thead {
    th {
      background: $lightGrey2;
      font-weight: $fw600;
      color: $blackCoral;
      padding: 12px;
      white-space: nowrap;
    }
  }

  td {
    border-top: 1px solid $gainsboro;
    padding: 10px;
  }
}

.grey-heading {
  color: $graniteGray;
}

.gh-navbar {
  background: $white;
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.05);
}

.page-with-footer {
  margin-bottom: 54px;
}

.width-300 {
  width: 300px;
}

.daterangepicker {
  .drp-selected {
    display: none !important;
  }
}

.set-background-color {
  background-color: $lightGrey2;
}

.set-boldness {
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 20px;
}

.set-left-margin {
  margin-left: 8px;
}

.set-margin-add-option {
  margin-left: 16%;
}

.margin-left-opt {
  margin-left: 1%;
}

.likert-text {
  padding: 3%;
}

.margin-left-initial {
  margin-left: -0.5%;
}

.add-question {
  display: contents;
}

.cancelActivityModal {
  width: 60%;
}

.border-radius-0 {
  border-radius: 0px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.width-p65 {
  width: 65% !important;
}

.label_display {
  width: 40px;
  height: 40px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
//  ___________________________________ styles that are used across multiple components________________________________________________

//  ________________________ other styles that maybe indirectly used in any component (not removing for safety)________________________
.btn-font {
  font-size: $sp30 !important;
  padding: 0px 5px 0px 0px;
}

.btn-margin-left {
  margin-left: 15px;
}

.width-p100 {
  width: 100%;
}

.set-mt {
  margin-top: -37px;
}

.dropdown-toggle {
  white-space: nowrap;
  color: $primaryBlue;
  background-color: $white;
  border-width: 0px;
}

.gh-outlined-select {
  padding: 8px 32px 8px 24px;
}

.page-item {
  &.active .page-link {
    z-index: 3;
    color: $primaryBlue;
    background-color: $lightBlue;
    border-color: $primaryBlue;
  }
}

mark.rbt-highlight-text {
  display: none;
}

a.rbt-menu-custom-option {
  color: $azure;
  font-weight: $fw600;

  &:hover {
    color: $azure;
  }
}

.sr-only {
  display: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
  padding-right: 0 !important;
}

.css-12a83d4-MultiValueRemove {
  display: none !important;
}
//  ________________________ other styles that maybe indirectly used in any component (not removing for safety)________________________
