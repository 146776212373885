@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap");

// primary
$blue: blue;
$black: black;
$white: white;
$originalGreen: green;
$originalRed: red;
$purple: #8a4baf;
$darkPurple: rgb(99, 11, 99);

// blue varieties
$primaryBlue: #1e64d7;
$lightBlue: #f1f6ff;
$softBlue: #b9d3fb;
$mediumBlue: #0087b3;
$darkBlue: #0e2b5b;
$darkerBlue: #051b23;
$blueGrey: #35578e;
$transparentBlue: rgba(30, 100, 215, 0.2);
$denim: #1459cb;
$azure: #007bff;
$blueberry: #468cff;
$lavenderBlue: #c7ceff;
$lavenderBlush: #fef5f5;

// green varieties
$primaryGreen: #36bb9b;
$green: #72b93b;
$lightGreen: #cdefe7;
$darkGreen: #008e76;
$neonGreen: #9bf356;
$paoloVeroneseGreen: #059f7a;

// grey varieties
$lightGrey2: #f5f6f8;
$lightGray3: #c6ccd8;
$mediumGrey: #b2b9c3;
$darkGrey: #7b839b;
$jetGrey: #3a3636d1;
$charcoal: #292627;
$graniteGray: #666666;
$darkCharcoal: #333333;
$GraniteGray2: #626262;
$darkLiver: #4d4d4d;
$darkLiver2: #4f4f4f;
$lightGrey: #d3d3d3;
$manatee: #959caf;
$disableButtonColor: rgba(255, 255, 255, 0.6);
$blackCoral: #555b6c;
$gainsboro: #d8dde4;
$lightCoral: #858a94;
$grey: #eff1f4;
$dCharcoal: rgba(0, 0, 0, 0.6);
$lgrey: #c5c5c5;
$lg: #ffffff99;
$dg: #a5adc5;
$lightGrey4: #0000008a;

// yellow varities
$yellow2: #f8c137;
$darkYellow: rgba(248, 193, 55, 0.15);
$lightYellow: #fffbf3;
$lYellow: #eaf4e2;
$lY: #dbe9ff;

// red varieties
$red: #e83d3d;
$pinkLace: #f9d4ff;
$orange: #ffa048;
$pink: #ff2267;

// white varieties
$chineseWhite: #e1e1e1;
$lightWhite: #e5e5e5;
$mediumWhite: #fff4e5;
$offWhite: #eeeeee;
$offWhiteBg: #e0e0e0;

// size variables
$sp10: 10px;
$sp12: 12px;
$sp14: 14px;
$sp15: 15px;
$sp16: 16px;
$sp18: 18px;
$sp20: 20px;
$sp22: 22px;
$sp24: 24px;
$sp28: 28px;
$sp30: 30px;
$sp32: 32px;
$sp34: 34px;
$sp36: 36px;
$sp48: 48px;

// font weight
$fw100: 100;
$fw200: 200;
$fw300: 300;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw800: 800;

// font family
$ffNunitoSans: "Nunito Sans", sans-serif;
