@import "../../../assets/styles/variables.scss";

.comp {
  background: $chineseWhite;
  font-size: $sp12;
  text-align: center;
  padding: 15px;
  font-weight: $fw600;
  margin-bottom: 0px;
}

.att-size {
  font-size: $sp12;
  float: right;
  margin-right: 53px;
  right: 1px;
  margin-bottom: 7px;
  padding-top: -12px;
}

.opt-image {
  width: 20px;
  position: unset;
}
